import { configureStore } from "@reduxjs/toolkit";

import authReducer from "@a1-ict/core/authSlice";
import dialogReducer from "@a1-ict/core/dialogSlice";
import propertyReducer from "@a1-ict/fsbo/propertySlice";
import searchReducer from "@a1-ict/fsbo/searchSlice";
import userReducer from "@a1-ict/fsbo/userSlice";
import paymentReducer from "@a1-ict/fsbo/paymentSlice";
import bannerReducer from "@a1-ict/fsbo/bannerSlice";
import generalReducer from "@a1-ict/fsbo/generalSlice";
import calculatorReducer from "@a1-ict/fsbo/calculatorSlice";
import notificationsReducer from "./notificationsSlice";

export default (settings) =>
  configureStore({
    reducer: {
      auth: authReducer,
      dialog: dialogReducer,
      user: userReducer,
      property: propertyReducer,
      search: searchReducer,
      payment: paymentReducer,
      banner: bannerReducer,
      general: generalReducer,
      notifications: notificationsReducer,
      calculator: calculatorReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        thunk: {
          extraArgument: { settings },
        },
      }),
  });
