import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "querystring";

import { client } from "@a1-ict/core/client";

export const fetchGolden = createAsyncThunk(
  "property/fetchGolden",
  async ({ queryParams = {} }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;
    let query_url = `${api_url}/Property/golden`;

    if (queryParams && Object.keys(queryParams).length) {
      query_url = `${query_url}?${stringify(queryParams)}`;
    }

    return await client.get(dispatch, settings, query_url, {
      noLoader: true,
    });
  }
);

export const fetchSilver = createAsyncThunk(
  "property/fetchSilver",
  async ({ queryParams }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;
    let query_url = `${api_url}/Property/silver`;

    if (queryParams && Object.keys(queryParams).length) {
      query_url = `${query_url}?${stringify(queryParams)}`;
    }

    return await client.get(dispatch, settings, query_url, {
      noLoader: true,
    });
  }
);

export const fetchBuildings = createAsyncThunk(
  "property/fetchBuildings",
  async ({ queryParams }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;
    let query_url = `${api_url}/Property/building`;

    if (queryParams && Object.keys(queryParams).length) {
      query_url = `${query_url}?${stringify(queryParams)}`;
    }

    return await client.get(dispatch, settings, query_url, {
      noLoader: true,
    });
  }
);

export const fetchPropertyParams = createAsyncThunk(
  "property/fetchPropertyParams",
  async (_, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/Property/parameters`
    );
  }
);

export const fetchParamsSpecific = createAsyncThunk(
  "search/fetchParamsSpecific",
  async ({ dispatch, settings, queryString }) => {
    const { api_url } = settings;

    return await client.get(dispatch, settings, `${api_url}/${queryString}`);
  }
);

export const createProperty = createAsyncThunk(
  "property/create",
  async ({ dispatch, settings, body }) => {
    const { api_url } = settings;

    return await client.post(
      dispatch,
      settings,
      `${api_url}/Property`,
      { ...body, agencyAgreement: true },
      {
        formData: true,
        action: "createProperty",
      }
    );
  }
);

export const editProperty = createAsyncThunk(
  "property/edit",
  async ({ dispatch, settings, body }) => {
    const { api_url } = settings;

    return await client.put(
      dispatch,
      settings,
      `${api_url}/Property/${body.id}`,
      body,
      {
        formData: true,
        action: "editProperty",
      }
    );
  }
);

export const deleteProperty = createAsyncThunk(
  "property/deleteProperty",
  async ({ id }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.delete(dispatch, settings, `${api_url}/Property/${id}`);
  }
);

export const archiveProperty = createAsyncThunk(
  "property/archiveProperty",
  async ({ id }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.delete(
      dispatch,
      settings,
      `${api_url}/Property/${id}/archive`
    );
  }
);

export const fetchProperties = createAsyncThunk(
  "property/fetchProperties",
  async ({ queryParams }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    queryParams.perPage = 20;
    queryParams.CurrentPage = queryParams.CurrentPage || 1;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/Property?${stringify(queryParams)}`
    );
  }
);

export const fetchPropertyCategories = createAsyncThunk(
  "property/fetchPropertyCategories",
  async ({ dispatch, settings }) => {
    const { api_url } = settings;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/Property/categories`,
      {
        noLoader: true,
      }
    );
  }
);

export const fetchProperty = createAsyncThunk(
  "property/fetchProperty",
  async ({ id, isEdit = false }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/Property/${id}?is_edit=${isEdit}`
    );
  }
);

export const fetchContactsFromSMS = createAsyncThunk(
  "property/fetchPropertyContacts",
  async ({ propertyId, code }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(
      dispatch,
      settings,
      // `${api_url}/Property/${propertyId}/contacts?code=${code}`,
      `${api_url}/Property/${propertyId}/contacts`,
      { action: "viewContact" }
    );
  }
);

export const fetchContactsFromBalance = createAsyncThunk(
  "property/fetchContactsFromBalance",
  async ({ propertyId }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/Property/${propertyId}/contacts/buy`
    );
  }
);

export const fetchDistricts = createAsyncThunk(
  "property/fetchDistricts",
  async (_, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/Property/districts`
    );
  }
);

export const fetchCities = createAsyncThunk(
  "property/fetchCities",
  async ({ id }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/Property/cities?District=${id}`
    );
  }
);

export const fetchNeighborhoods = createAsyncThunk(
  "property/fetchNeighborhoods",
  async ({ id }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/Property/neighborhoods?City=${id}`
    );
  }
);

export const fetchStaticMap = createAsyncThunk(
  "property/fetchStaticMap",
  async ({ queryParams }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;
    let query_url = `${api_url}/Property/static-map`;

    if (queryParams && Object.keys(queryParams).length) {
      query_url = `${query_url}?${stringify(queryParams)}`;
    }

    return await client.get(dispatch, settings, query_url);
  }
);

export const fetchCityDistrictCoords = createAsyncThunk(
  "property/fetchCityDistrictCoords",
  async ({ queryParams }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;
    let query_url = `${api_url}/Property/place-location`;

    if (queryParams && Object.keys(queryParams).length) {
      query_url = `${query_url}?${stringify(queryParams)}`;
    }

    return await client.get(dispatch, settings, query_url);
  }
);

export const reportProperty = createAsyncThunk(
  "property/report",
  async ({ dispatch, settings, body }) => {
    const { api_url } = settings;

    return await client.post(
      dispatch,
      settings,
      `${api_url}/property/report`,
      body,
      {
        action: "reportProperty",
      }
    );
  }
);
