import { createSlice } from "@reduxjs/toolkit";
import {
  fetchPromotePackages,
  createPayment,
  payFromBalance,
  fetchWalletBonuses,
} from "./requests";

const initialState = {
  promotePackages: [],
  walletBonuses: {
    items: [],
    total_items: 0,
    total_pages: 0,
    current_page: 0,
    page_size: 0,
  },
  successfullyPaid: false,
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPromotePackages.fulfilled]: (state, action) => {
      state.promotePackages = action.payload.data.packages;
    },
    [fetchWalletBonuses.fulfilled]: (state, action) => {
      state.walletBonuses = action.payload.data;
    },
    [createPayment.fulfilled]: (state, action) => {
    },
    [payFromBalance.fulfilled]: (state, action) => {
      state.successfullyPaid = true;
    },
  },
});

export {
  createPayment,
  fetchPromotePackages,
  fetchWalletBonuses,
  payFromBalance,
};

export default paymentSlice.reducer;
