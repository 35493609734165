import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "querystring";
import { client } from "@a1-ict/core/client";

export const fetchMyProperties = createAsyncThunk(
  "user/fetchMyProperties",
  async ({ queryParams }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;
    queryParams.perPage = queryParams.perPage || 10;
    queryParams.CurrentPage = queryParams.CurrentPage || 1;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/user/properties?${stringify(queryParams)}`,
      {
        repeatFuncOnRefreshedToken: () =>
          fetchMyProperties({ queryParams: queryParams }),
      }
    );
  }
);

export const fetchMyPackageSlots = createAsyncThunk(
  "user/fetchMyPackageSlots",
  async ({ queryParams }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;
    queryParams.perPage = 10;
    queryParams.CurrentPage = queryParams.CurrentPage || 1;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/user/packages/property-slot?${stringify(queryParams)}`,
      {
        repeatFuncOnRefreshedToken: () =>
          fetchMyPackageSlots({ queryParams: queryParams }),
      }
    );
  }
);

export const fetchMyPackageSlotDetails = createAsyncThunk(
  "user/fetchMyPackageSlotDetails",
  async (_, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/user/packages/properties-slot-details`,
      {
        repeatFuncOnRefreshedToken: () =>
          fetchMyPackageSlotDetails({ queryParams: queryParams }),
      }
    );
  }
);

export const fetchMyDetails = createAsyncThunk(
  "user/fetchMyDetails",
  async (_, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(dispatch, settings, `${api_url}/user/details`, {
      repeatFuncOnRefreshedToken: fetchMyDetails,
    });
  }
);

export const fetchInvoiceDetails = createAsyncThunk(
  "user/fetchInvoiceDetails",
  async (_, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/user/invoice-details`,
      {
        repeatFuncOnRefreshedToken: fetchInvoiceDetails,
      }
    );
  }
);

export const editInvoiceDetails = createAsyncThunk(
  "user/editInvoiceDetails",
  async ({ body }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.put(
      dispatch,
      settings,
      `${api_url}/user/invoice-details`,
      body,
      {
        action: "editInvoiceDetails",
      }
    );
  }
);

export const editName = createAsyncThunk(
  "user/editName",
  async ({ body }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.put(dispatch, settings, `${api_url}/user/name`, body, {
      action: "editName",
    });
  }
);

export const editEmail = createAsyncThunk(
  "user/editEmail",
  async ({ body }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.put(dispatch, settings, `${api_url}/user/email`, body, {
      action: "editEmail",
    });
  }
);

export const verifyNewEmail = createAsyncThunk(
  "user/verifyNewEmail",
  async ({ code }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.post(
      dispatch,
      settings,
      `${api_url}/user/email/verify`,
      { code },
      {
        action: "verifyNewEmail",
      }
    );
  }
);

export const editPhone = createAsyncThunk(
  "user/editPhone",
  async ({ body }, { dispatch, extra: { settings } }) => {

    const { api_url } = settings;

    return await client.put(dispatch, settings, `${api_url}/user/phone`, body, {
      action: "editPhone",
    });
  }
);

export const verifyNewPhone = createAsyncThunk(
  "user/verifyNewPhone",
  async ({ dispatch, settings, code }) => {
    const { api_url } = settings;
    return await client.post(
      dispatch,
      settings,
      `${api_url}/user/phone/verify`,
      { code },
      {
        action: "verifyNewPhone",
      }
    );
  }
);

export const resendNewPhoneCode = createAsyncThunk(
  "user/resendNewPhoneCode",
  async (_, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;
    return await client.post(
      dispatch,
      settings,
      `${api_url}/user/resend-phone-code`,
      {},
      {
        action: "resendNewPhoneCode",
      }
    );
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async ({ dispatch, settings, password }) => {
    const { api_url } = settings;
    return await client.delete(
      dispatch,
      settings,
      `${api_url}/user`,
      { password },
      {
        action: "deleteUser",
      }
    );
  }
);

export const addFavoriteProperty = createAsyncThunk(
  "user/addFavoriteProperty",
  async ({ dispatch, settings, property_id }) => {
    const { api_url } = settings;
    return await client.post(
      dispatch,
      settings,
      `${api_url}/favorite`,
      { property_id },
      {
        action: "addFavoriteProperty",
        noLoader: true,
      }
    );
  }
);

export const deleteFavoriteProperty = createAsyncThunk(
  "user/deleteFavoriteProperty",
  async ({ dispatch, settings, property_id }) => {
    const { api_url } = settings;
    return await client.delete(
      dispatch,
      settings,
      `${api_url}/favorite/${property_id}`,
      {},
      {
        action: "deleteFavoriteProperty",
        noLoader: true,
      }
    );
  }
);

export const fetchFavoriteProperties = createAsyncThunk(
  "property/fetchFavoriteProperties",
  async ({ dispatch, settings, queryParams }) => {
    const { api_url } = settings;

    queryParams.perPage = 20;
    queryParams.CurrentPage = queryParams.CurrentPage || 1;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/favorite?${stringify(queryParams)}`
    );
  }
);

export const saveUserSearch = createAsyncThunk(
  "user/saveSearch",
  async ({ dispatch, settings, body }) => {
    const { api_url } = settings;

    return await client.post(
      dispatch,
      settings,
      `${api_url}/user/saved-search`,
      body,
      {
        action: "saveSearch",
        successMessage: "Успешно запазено търсене",
      }
    );
  }
);

export const fetchUserSearches = createAsyncThunk(
  "user/fetchSearches",
  async ({ dispatch, settings, queryParams }) => {
    const { api_url } = settings;

    queryParams.perPage = 20;
    queryParams.CurrentPage = queryParams.CurrentPage || 1;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/user/saved-search?${stringify(queryParams)}`
    );
  }
);

export const fetchUserTransactions = createAsyncThunk(
  "user/fetchUserTransactions",
  async ({ dispatch, settings, queryParams }) => {
    const { api_url } = settings;

    queryParams.perPage = 20;
    queryParams.CurrentPage = queryParams.CurrentPage || 1;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/user/transactions?${stringify(queryParams)}`
    );
  }
);

export const hasInvoiceDetails = createAsyncThunk(
  "user/hasInvoiceDetails",
  async (_, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/user/invoice-details/check`
    );
  }
);

export const downloadInvoice = createAsyncThunk(
  "user/downloadInvoice",
  async ({ id }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/transactions/${id}/invoice-download`, { contentType: "application/pdf", toBlob: true }
    );
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async ({ body }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.put(
      dispatch,
      settings,
      `${api_url}/user/password`,
      body
    );
  }
);

export const setClubNumber = createAsyncThunk(
  "user/setClubNumber",
  async ({ body }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.post(
      dispatch,
      settings,
      `${api_url}/user/club-number`,
      body
    );
  }
);

export const editClubNumber = createAsyncThunk(
  "user/editClubNumber",
  async ({ body }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.put(
      dispatch,
      settings,
      `${api_url}/user/club-number`,
      body
    );
  }
);
