import { createSlice } from "@reduxjs/toolkit";

import {
  initApp,
  login,
  socialLogin,
  logout,
  register,
  update,
  refresh,
  reloadUser,
  verifyEmail,
  verifyPhoneNumber,
  resetPassword,
  validateEmail,
} from "./requests";

const initialState = {
  isLoggedIn: false,
  initingApp: false,
  isAppInited: false,
  isEmailUsed: false,
  isReloading: true,
  userData: {},
  isRefreshingToken: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Needed to manually log in user after success actions after the registration (FSBO usage)
    setLoggedIn: (state) => {
      state.isLoggedIn = true;
    },
    clearUserData: (state, action) => {
      state.userData = null;
    },
    setUserData: (state, action) => {
      state.userData = {
        ...state.userData,
        ...action.payload,
      };
    },
    setReloadingUser: (state, action) => {
      state.isReloading = action.payload
    }
  },
  extraReducers: {
    [initApp.pending]: (state, action) => {
      state.initingApp = true;
    },
    [initApp.fulfilled]: (state, action) => {
      state.isLoggedIn = !!action.payload.ok;
      state.userData = action.payload.user;
      state.isAppInited = true;
      state.initingApp = false;
    },
    [initApp.rejected]: (state) => {
      Object.assign(state, initialState);
      state.isAppInited = true;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = action.payload.ok;
      state.userData = action.payload.user;
    },

    [refresh.pending]: (state) => {
      state.isRefreshingToken = true;
    },
    [refresh.fulfilled]: (state) => {
      state.isRefreshingToken = false;
    },
    [refresh.rejected]: (state) => {
      state.isRefreshingToken = false;
    },
    [socialLogin.fulfilled]: (state, action) => {
      state.isLoggedIn = action.payload.ok;
      state.userData = action.payload.user;
    },
    [register.fulfilled]: (state, action) => {
      state.userData = action.payload.user;
    },
    [update.fulfilled]: (state, action) => {
      state.userData = action.payload.user;
    },
    [reloadUser.fulfilled]: (state, action) => {
      state.userData = action.payload.user;
    },
    [logout.fulfilled]: (state) => {
      state.userData = null;
      state.isLoggedIn = false;
    },
    [verifyEmail.fulfilled]: (state, action) => {
      state.userData = action.payload.user;
    },
    [verifyPhoneNumber.fulfilled]: (state, action) => {
      state.userData = action.payload.user;
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.userData = action.payload.user;
    },
    [validateEmail.fulfilled]: (state, action) => {
      state.isEmailUsed = action.payload.data.exists;
    },
  },
});

export const { setUserData, clearUserData, setLoggedIn, setReloadingUser } = authSlice.actions;

export * from "./requests";
export * from "./selectors";

export { useAuthorization } from "./useAuthorization";

export default authSlice.reducer;
