import { createSlice } from "@reduxjs/toolkit";

import {
  fetchFilters,
  fetchFiltersSpecific,
  fetchAddresses,
  fetchAddressById,
  saveLastSearches,
  getLastSearches,
} from "./requests";

const emptyFiltersSPecific = {
  filters: [],
  categories: [],
  features: [],
  additional: {},
};

const initialState = {
  filters: [],
  filtersSpecific: emptyFiltersSPecific,
  queryParams: {},
  lastSearches: [],
  addresses: [],
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setQueryString: (state, action) => {
      state.queryParams = action.payload;
    },
    removeFiltersSpecific: (state, action) => {
      state.filtersSpecific = emptyFiltersSPecific;
    },
  },
  extraReducers: {
    [fetchFilters.fulfilled]: (state, action) => {
      state.filters = action.payload.data.filters;
    },
    [fetchFiltersSpecific.fulfilled]: (state, action) => {
      state.filtersSpecific = action.payload.data;
    },
    [fetchAddresses.fulfilled]: (state, action) => {
      state.addresses = action.payload.data;
    },
    [fetchAddressById.fulfilled]: (state, action) => {
      state.addresses = [action.payload.data];
    },
    [saveLastSearches.fulfilled]: (state, action) => {
      state.lastSearches = action.payload;
    },
    [getLastSearches.fulfilled]: (state, action) => {
      state.lastSearches = action.payload;
    },
  },
});

export const { setQueryString, removeFiltersSpecific } = searchSlice.actions;

export {
  fetchFilters,
  fetchFiltersSpecific,
  fetchAddresses,
  fetchAddressById,
  saveLastSearches,
  getLastSearches,
};

export default searchSlice.reducer;
