import { createAsyncThunk } from "@reduxjs/toolkit";

import { client } from "@a1-ict/core/client";

export const sendContact = createAsyncThunk(
  "general/sendContact",
  async ({ dispatch, settings, body }) => {
    const { api_url } = settings;

    return await client.post(
      dispatch,
      settings,
      `${api_url}/contact/send-inquiry`,
      body,
      {
        action: "sendContact",
      }
    );
  }
);

export const sendServiceInquiry = createAsyncThunk(
  "general/sendServiceInquiry",
  async ({ dispatch, settings, serviceType, serviceId, body }) => {
    const { api_url } = settings;

    return await client.post(
      dispatch,
      settings,
      `${api_url}/service/${serviceType}/${serviceId}/send-inquiry`,
      body
    );
  }
);

export const fetchFsboContacts = createAsyncThunk(
  "general/fetchFsboContacts",
  async (_, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(dispatch, settings, `${api_url}/contact/main`);
  }
);

export const fetchAboutUs = createAsyncThunk(
  "general/fetchAboutUs",
  async (_, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(dispatch, settings, `${api_url}/aboutus`);
  }
);

export const fetchCarousel = createAsyncThunk(
  "general/fetchCarousel",
  async (_, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(dispatch, settings, `${api_url}/carousel`);
  }
);
