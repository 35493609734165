import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "@a1-ict/core/client";
import { stringify } from "querystring";

export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async ({ queryParams = {} }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    queryParams.perPage = queryParams.perPage || 5;
    queryParams.CurrentPage = queryParams.CurrentPage || 1;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/notification?${stringify(queryParams)}`
    );
  }
);

export const readNotification = createAsyncThunk(
  "notifications/readNotification",
  async ({ id }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.put(
      dispatch,
      settings,
      `${api_url}/notification/${id}/read`
    );
  }
);

export const deleteNotification = createAsyncThunk(
  "notifications/deleteNotification",
  async ({ ids }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    const formatIds = (array) => {
      let query = "";
      for (let i = 0; i < array.length; i++) {
        query = query + "id=" + array[i] + (i + 1 === array.length ? "" : "&");
      }
      return query;
    };

    return await client.delete(
      dispatch,
      settings,
      `${api_url}/notification?${formatIds(ids)}`
    );
  }
);
