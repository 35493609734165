import { storage } from '@a1-ict/gatsby/storage';

const settings = {
  // errorMode: 'dialog',
  errorMode: 'error',
  storage,
  api_url: process.env.GATSBY_BACKEND,
  errors: {
    unexpectedError: 'Възникна неочаквана грешка! Моля, опитайте по-късно!',
    LOGIN_ERROR: 'Грешен имейл или парола.',
    EC_NAME_IS_REQUIRED: 'Името е задължително.',
    EC_DESCRIPTION_IS_REQUIRED: 'Описанието е задължително.',
    EC_INVALID_PHONE_NUMBER: 'Невалиден телефонен номер.',
    EC_TERMS_CONSENT_IS_REQUIRED: 'Моля съгласете се с условията.',
    EC_INVALID_TOKEN: 'Невалидна проверка дали сте робот.',
    EC_PHONE_IS_REQUIRED: 'Телефонният номер е задължителен.',
    EC_PHONE_NUMBER_BLACKLISTED: 'Този номер е блокиран.',
    EC_PHONE_NUMBER_ALREADY_EXISTS: 'Телефонният номер вече съществува.',
    EC_IVALID_IMAGE_DIMENSIONS: 'Моля уверете се, че размерите на снимките са минумум 1024х576.',
    EC_UIC_ALREADY_EXISTS: 'Въведеният ЕИК/Булстат вече съществува.',
    EC_EMAIL_IS_REQUIRED: 'Имейлът е задължителен.',
    EC_EMAIL_BLACKLISTED: 'Този имейл е блокиран.',
    EC_USER_ALREADY_EXISTS: 'Потребител с тези данни вече съществува.',
    EC_COMPANY_VAT_IS_REQUIRED: 'Моля, въведете коректни ЕИК/Булстат и Данъчен номер.',
    EC_PROPERTY_LOCATION_NOT_FOUND: 'Адреса не е намерен.',
    EC_USER_ALREADY_EXISTS: 'Въведеният e-mail вече съществува.',
    EC_LOGIN_ERROR: 'Въведената парола е грешна.',
    EC_COMPANY_OWNER_IS_REQUIRED: 'Полето МОЛ е задължително.',
    EC_EMAIL_BLACKLISTED: 'Този e-mail е блокиран.',
    EC_COMPANY_NAME_IS_REQUIRED: 'Името на фирмата е задължително.',
    EC_COMPANY_UIC_IS_REQUIRED: 'ЕИК/Булстат е задължителен.',
    EC_COMPANY_ADDRESS_IS_REQUIRED: 'Адресът е задължителен.',
    EC_MISSING_FREE_PROPERTY_SLOT: 'Достигнат лимит за обикновени обяви!',
    EC_SUBJECT_IS_REQUIRED: `Полето: "Относно" е задължително.`,
    EC_INSUFFICIENT_FUNDS: 'Недостатъчен наличен баланс!',
    EC_USER_NOT_FOUND: 'Несъществуващ потребител или грешен e-mail адрес.',
    EC_INVALID_CODE: 'Въведеният код е невалиден!',
    EC_INVALID_CLUB_NUMBER: 'Невалиден клубен номер!',
    EC_PHONE_CODE_LIMITS_EXCEEDED:
      'Достигнали сте лимита за повторно изпращане! Моля изчакайте няколко минути, за да получите SMS.',
    EC_INVALID_CREDIT_SIZE: 'Невалидна сума!',
    EC_INVALID_CREDIT_MONTHS: 'Невалиден срок!',
  },
  asyncCalls: {},
};

export { settings };
