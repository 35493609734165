export const selectIsAppInited = (state) => state.auth.isAppInited;

export const selectInitingApp = (state) => state.auth.initingApp;

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;

export const selectIsRefreshingToken = (state) => state.auth.isRefreshingToken;

export const selectUserData = (state) => state.auth.userData;

export const isEmailUsed = (state) => state.auth.isEmailUsed;

export const isReloadingUser = (state) => state.auth.isReloading;