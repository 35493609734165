import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "querystring";
import { client } from "@a1-ict/core/client";

export const fetchFilters = createAsyncThunk(
  "search/fetchFilters",
  async ({ dispatch, settings }) => {
    const { api_url } = settings;

    return await client.get(dispatch, settings, `${api_url}/Property/filters`);
  }
);

export const fetchFiltersSpecific = createAsyncThunk(
  "search/fetchFiltersSpecific",
  async ({ queryString }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(dispatch, settings, `${api_url}/${queryString}`, {
      noLoader: true,
    });
  }
);

export const fetchAddresses = createAsyncThunk(
  "search/fetchAddresses",
  async ({ searchString, itemsCount }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;
    const items = itemsCount || 10;
    const queryParams = { PlaceName: searchString, ResultsCount: items };

    return await client.get(
      dispatch,
      settings,
      `${api_url}/search?${stringify(queryParams)}`,
      {
        noLoader: true,
      }
    );
  }
);

export const fetchAddressById = createAsyncThunk(
  "search/fetchAddressById",
  async ({ queryString }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/search/place?${queryString}`,
      {
        noLoader: true,
      }
    );
  }
);

export const saveLastSearches = createAsyncThunk(
  "search/saveLastSearches",
  async ({ filters, maxElements = 0 }, { extra: { settings } }) => {
    const { storage } = settings;
    let currentSavedSearches = await storage.getLastSearches();
    currentSavedSearches = JSON.parse(currentSavedSearches) || [];
    currentSavedSearches.unshift(filters);

    if (maxElements && currentSavedSearches.length >= maxElements) {
      currentSavedSearches = currentSavedSearches.slice(0, maxElements);
    }

    await storage.setLastSearches(JSON.stringify(currentSavedSearches));
    return currentSavedSearches;
  }
);

export const getLastSearches = createAsyncThunk(
  "search/getLastSearches",
  async ({ settings }) => {
    const { storage } = settings;
    let searches = await storage.getLastSearches();
    return JSON.parse(searches);
  }
);
