import React from 'react';
import { Provider } from 'react-redux';

import { BreakpointProvider } from '@a1-ict/gatsby/utils/breakpoint';
import { LocationProvider } from '@a1-ict/gatsby/utils/locationContext';

import Spinner from './src/components/Common/Spinner';

import { settings } from './src/settings';

import createStore from '@a1-ict/fsbo/store';
import { initApp } from '@a1-ict/core/authSlice';
import { fetchPropertyCategories } from '@a1-ict/fsbo/propertySlice';
import { fetchFilters } from '@a1-ict/fsbo/searchSlice';

const queries = {
  xxs: '(max-width: 380px)',
  xs: '(max-width: 576px)',
  sm: '(max-width: 768px)',
  md: '(max-width: 992px)',
  lg: '(max-width: 1200px)',
  xl: '(max-width: 1400px)',
  or: '(orientation: portrait)', // we can check orientation also
};

// eslint-disable-next-line react/display-name,react/prop-types
const wrapper = ({ element }) => {
  const store = createStore(settings);
  store.dispatch(fetchPropertyCategories({ dispatch: store.dispatch, settings }));
  store.dispatch(initApp());
  store.dispatch(fetchFilters({ dispatch: store.dispatch, settings }));

  return (
    <Provider store={store}>
      <BreakpointProvider queries={queries}>
        <LocationProvider>
          {element}
          <Spinner />
        </LocationProvider>
      </BreakpointProvider>
    </Provider>
  );
};

export default wrapper;
