import { createSlice } from "@reduxjs/toolkit";
import { fetchBanner, fetchLogos } from "./requests";

const initialState = {
  logos: [],
  banner: {},
};

export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchLogos.fulfilled]: (state, action) => {
      state.logos = action.payload.data;
    },
    [fetchBanner.fulfilled]: (state, action) => {
      state.banner = action.payload.data;
    },
  },
});

export { fetchLogos, fetchBanner };

export default bannerSlice.reducer;
