import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showLoader: 0,
  commonDialog: null,
  namedDialogs: {},
  errors: {},
};

const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    showLoader: (state) => {
      state.commonDialog === null;
      state.showLoader++;
    },
    hideLoader: (state) => {
      state.showLoader--;
    },
    showCommonDialog: (state, action) => {
      // state.showLoader = false;
      state.commonDialog = action.payload;
    },
    hideCommonDialog: (state) => {
      state.commonDialog = null;
    },
    showDialog: (state, action) => {
      const { name } = action.payload;

      // state.showLoader = false;
      state.namedDialogs[name] = true;
    },
    hideDialog: (state, action) => {
      const { name } = action.payload;

      state.namedDialogs[name] = false;
    },
    toggleDialog: (state, action) => {
      const { name, onCLose } = action.payload;

      if (state.namedDialogs[name] === true) {
        if (onCLose) onCLose();
        state.namedDialogs[name] = false;
      } else {
        state.namedDialogs[name] = true;
      }
    },
    setError: (state, action) => {
      const { name, error } = action.payload;

      state.errors[name] = error;
    },
    clearError: (state, action) => {
      const { name } = action.payload;
      let copyOfState = { ...state.errors };
      delete copyOfState[name];
      state.errors = copyOfState;
    },
  },
  extraReducers: {},
});

export const {
  showLoader,
  hideLoader,
  showCommonDialog,
  hideCommonDialog,
  showDialog,
  hideDialog,
  toggleDialog,
  setError,
  clearError,
} = dialogSlice.actions;

export * from "./selectors";

export default dialogSlice.reducer;
