import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../client";

export const initApp = createAsyncThunk(
  "auth/initApp",
  async (_, { dispatch, extra: { settings } }) => {
    return await loadUser(dispatch, settings);
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    const response = await client.post(
      dispatch,
      settings,
      `${api_url}/Authentication/login`,
      {
        email,
        password,
      },
      {
        action: "auth_login",
      }
    );

    return await setTokensAndLoadUser(dispatch, settings, response);
  }
);

export const socialLogin = createAsyncThunk(
  "auth/socialLogin",
  async ({ provider, access_token }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    const response = await client.post(
      dispatch,
      settings,
      `${api_url}/Authentication/${provider}`,
      {
        access_token,
      },
      {
        action: "auth_social_login",
      }
    );

    if (response.data.temporary_user) {
      return {
        ok: false,
        user: { ...response.data.temporary_user, is_social: true },
      };
    }

    return await setTokensAndLoadUser(dispatch, settings, response);
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { extra: { settings } }) => {
    await settings.storage.removeTokens();
    return null;
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async ({ request }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    const response = await client.post(
      dispatch,
      settings,
      request.is_social
        ? `${api_url}/Authentication/create/third-party`
        : `${api_url}/Authentication/create`,
      request,
      {
        action: "auth_register",
      }
    );

    return await setTokensAndLoadUser(dispatch, settings, response);
  }
);

export const update = createAsyncThunk(
  "auth/update",
  async ({ request }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    const response = await client.put(
      dispatch,
      settings,
      `${api_url}/Authentication/update`,
      request,
      {
        action: "auth_update",
      }
    );

    return await loadUser(dispatch, settings, response);
  }
);

export const resendCode = createAsyncThunk(
  "auth/resend-code",
  async ({ username }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.post(
      dispatch,
      settings,
      `${api_url}/Authentication/resend-code`,
      {
        email: username,
      },
      {
        action: "auth_resend_code",
      }
    );
  }
);

export const resendPhoneCode = createAsyncThunk(
  "auth/resend-phone-code",
  async ({ email }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.post(
      dispatch,
      settings,
      `${api_url}/Authentication/resend-phone-code`,
      {
        email: email,
      },
      {
        action: "auth_resend_phone_code",
      }
    );
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async ({ email, code }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    const response = await client.post(
      dispatch,
      settings,
      `${api_url}/Authentication/verify/email`,
      {
        email,
        code,
      },
      {
        action: "auth_verify_email",
      }
    );

    return await setTokensAndLoadUser(dispatch, settings, response);
  }
);

export const validateEmail = createAsyncThunk(
  "auth/validate-email",
  async ({ email }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.post(
      dispatch,
      settings,
      `${api_url}/Authentication/validate-email`,
      {
        email: email,
      },
      {
        action: "auth_validate_email",
      }
    );
  }
);

export const verifyPhoneNumber = createAsyncThunk(
  "auth/verifyPhoneNumber",
  async ({ email, code }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    const response = await client.post(
      dispatch,
      settings,
      `${api_url}/Authentication/verify/phone`,
      {
        email,
        code,
      },
      {
        action: "auth_verify_phone_number",
      }
    );

    return await setTokensAndLoadUser(dispatch, settings, response);
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgot-password",
  async ({ email }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.post(
      dispatch,
      settings,
      `${api_url}/Authentication/forgot-password`,
      {
        email,
      },
      {
        action: "auth_forgot_password",
      }
    );
  }
);

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  async ({ email, new_password, code }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    const response = await client.post(
      dispatch,
      settings,
      `${api_url}/Authentication/reset-password`,
      {
        email,
        new_password,
        code,
      },
      {
        action: "auth_reset_password",
      }
    );

    return await setTokensAndLoadUser(dispatch, settings, response);
  }
);

export const changePassword = createAsyncThunk(
  "auth/change-password",
  async ({ new_password, old_password }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.post(
      dispatch,
      settings,
      `${api_url}/Authentication/change-password`,
      { new_password, old_password },
      {
        action: "auth_change_password",
      }
    );
  }
);

export const refresh = createAsyncThunk(
  "auth/refresh-token",
  async ({ refresh_session_id }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.post(
      dispatch,
      settings,
      `${api_url}/Authentication/refresh`,
      { refresh_session_id },
      {
        action: "auth_refresh_token",
      }
    );
  }
);

export const reloadUser = createAsyncThunk(
  "auth/reload-user",
  async (_, { dispatch, extra: { settings } }) => {
    return loadUser(dispatch, settings);
  }
);

const setTokensAndLoadUser = async (dispatch, settings, response) => {
  const { storage } = settings;
  const hasTokens = await client.setTokens(storage, response);

  if (!hasTokens) {
    return { ok: false, user: null };
  }

  return loadUser(dispatch, settings);
};

const loadUser = async (dispatch, settings) => {
  const { api_url, storage } = settings;

  const userResponse = await client.get(
    dispatch,
    settings,
    `${api_url}/Authentication/user`,
    {
      action: "auth_load_user",
      noLoader: true,
    }
  );

  if (!userResponse.ok) {
    await client.removeTokens(storage);
  }

  return { ok: userResponse.ok, user: userResponse?.data };
};
