// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-building-js": () => import("./../../../src/pages/addBuilding.js" /* webpackChunkName: "component---src-pages-add-building-js" */),
  "component---src-pages-add-property-js": () => import("./../../../src/pages/addProperty.js" /* webpackChunkName: "component---src-pages-add-property-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-buy-view-js": () => import("./../../../src/pages/buy-view.js" /* webpackChunkName: "component---src-pages-buy-view-js" */),
  "component---src-pages-calculator-js": () => import("./../../../src/pages/calculator.js" /* webpackChunkName: "component---src-pages-calculator-js" */),
  "component---src-pages-dashboard-add-property-js": () => import("./../../../src/pages/dashboard/add-property.js" /* webpackChunkName: "component---src-pages-dashboard-add-property-js" */),
  "component---src-pages-dashboard-favourites-js": () => import("./../../../src/pages/dashboard/favourites.js" /* webpackChunkName: "component---src-pages-dashboard-favourites-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-messages-js": () => import("./../../../src/pages/dashboard/messages/[...].js" /* webpackChunkName: "component---src-pages-dashboard-messages-js" */),
  "component---src-pages-dashboard-my-profile-js": () => import("./../../../src/pages/dashboard/my-profile.js" /* webpackChunkName: "component---src-pages-dashboard-my-profile-js" */),
  "component---src-pages-dashboard-my-properties-js": () => import("./../../../src/pages/dashboard/my-properties.js" /* webpackChunkName: "component---src-pages-dashboard-my-properties-js" */),
  "component---src-pages-dashboard-searches-js": () => import("./../../../src/pages/dashboard/searches.js" /* webpackChunkName: "component---src-pages-dashboard-searches-js" */),
  "component---src-pages-dashboard-wallet-js": () => import("./../../../src/pages/dashboard/wallet.js" /* webpackChunkName: "component---src-pages-dashboard-wallet-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-property-js": () => import("./../../../src/pages/property/[...].js" /* webpackChunkName: "component---src-pages-property-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-successfully-paid-js": () => import("./../../../src/pages/successfullyPaid.js" /* webpackChunkName: "component---src-pages-successfully-paid-js" */),
  "component---src-pages-unsuccessfully-paid-js": () => import("./../../../src/pages/unsuccessfullyPaid.js" /* webpackChunkName: "component---src-pages-unsuccessfully-paid-js" */),
  "component---src-templates-infos-js": () => import("./../../../src/templates/infos.js" /* webpackChunkName: "component---src-templates-infos-js" */),
  "component---src-templates-property-js": () => import("./../../../src/templates/property.js" /* webpackChunkName: "component---src-templates-property-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-terms-js": () => import("./../../../src/templates/terms.js" /* webpackChunkName: "component---src-templates-terms-js" */)
}

