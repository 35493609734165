import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "querystring";
import { client } from "@a1-ict/core/client";

export const fetchPromotePackages = createAsyncThunk(
  "payment/fetchPromotePackages",
  async (
    { packageType, propertyId = null },
    { dispatch, extra: { settings } }
  ) => {
    const { api_url } = settings;

    if (propertyId)
      return await client.get(
        dispatch,
        settings,
        `${api_url}/payment/packages/${packageType}?propertyId=${propertyId}`
      );

    return await client.get(
      dispatch,
      settings,
      `${api_url}/payment/packages/${packageType}`
    );
  }
);

export const fetchWalletBonuses = createAsyncThunk(
  "payment/fetchWalletBonuses",
  async ({ queryParams = {} }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    queryParams.perPage = 10;
    queryParams.CurrentPage = queryParams.CurrentPage || 1;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/user-wallet-bonus?${stringify(queryParams)}`
    );
  }
);

export const createPayment = createAsyncThunk(
  "payment/createPayment",
  async ({ paymentType, body }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.post(
      dispatch,
      settings,
      `${api_url}/payment/epay/${paymentType}`,
      body,
      {
        action: "createPayment",
      }
    );
  }
);

export const payFromBalance = createAsyncThunk(
  "payment/payFromBalance",
  async ({ paymentType, body }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.post(
      dispatch,
      settings,
      `${api_url}/payment/pay-from-balance`,
      body,
      {
        action: "payFromBalance",
      }
    );
  }
);
