import { createSlice } from "@reduxjs/toolkit";

import {
  sendContact,
  sendServiceInquiry,
  fetchAboutUs,
  fetchFsboContacts,
  fetchCarousel,
} from "./requests";

const initialState = {
  aboutUs: {},
  fsboContacts: {},
  carousel: [],
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAboutUs.fulfilled]: (state, action) => {
      state.aboutUs = action.payload.data;
    },
    [fetchCarousel.fulfilled]: (state, action) => {
      const carouselItems = action.payload.data;
      let modifiedCarousel = [];
      if (Array.isArray(carouselItems) && carouselItems.length > 0)
        modifiedCarousel = carouselItems.map((item) => {
          return { title: item.text };
        });

      state.carousel = modifiedCarousel;
    },
    [fetchFsboContacts.fulfilled]: (state, action) => {
      state.fsboContacts = action.payload.data;
    },
  },
});

export const {} = generalSlice.actions;

export {
  sendContact,
  sendServiceInquiry,
  fetchAboutUs,
  fetchFsboContacts,
  fetchCarousel,
};

export default generalSlice.reducer;
