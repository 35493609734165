import { createAsyncThunk } from "@reduxjs/toolkit";

import { client } from "@a1-ict/core/client";

export const fetchDetails = createAsyncThunk(
  "calculator/fetchDetails",
  async (_, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/credit-calculator/details`
    );
  }
);

export const calculate = createAsyncThunk(
  "calculator/calculate",
  async (body, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.post(
      dispatch,
      settings,
      `${api_url}/credit-calculator/calculate`,
      body,
      {
        action: "calculate",
      }
    );
  }
);
