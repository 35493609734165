import React, { useState, useEffect, createContext, useContext } from "react";

const LocationContext = createContext();

const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(null);

  function handleLocationError(browserHasGeolocation, infoWindow) {
    return infoWindow;
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setLocation(pos);
        },
        () => {
          // User doesnt share his location
          setLocation({ permission: false });
          handleLocationError(
            true,
            alert(
              "Здравейте! За да предоставим имоти във Вашето обкръжение, моля разрешете да Ви вземем кординатите."
            )
          );
        }
      );
    } else {
      // Browser doesn't support Geolocation
      handleLocationError(
        false,
        alert(
          "Вашият браузър не поддържа локация, това може да повлияе на някои услуги!"
        )
      );
    }
  }, []);

  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  );
};

const useLocation = () => {
  const context = useContext(LocationContext);
  return context;
};

export { useLocation, LocationProvider };
