import { useState, useEffect, useCallback } from "react";

export const useAuthorization = (storage) => {
  const [accessToken, setAccessToken] = useState(null);

  const execute = useCallback(() => {
    storage.getAccessToken().then((x) => {
      setAccessToken(x);
    });
  }, []);

  useEffect(() => {
    execute();
  }, []);

  return accessToken ? `Session ${accessToken}` : null;
};
