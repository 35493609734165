import { createSlice } from "@reduxjs/toolkit";

import {
  fetchGolden,
  fetchSilver,
  fetchBuildings,
  fetchProperties,
  fetchProperty,
  fetchPropertyCategories,
  fetchPropertyParams,
  fetchParamsSpecific,
  fetchDistricts,
  fetchCities,
  fetchNeighborhoods,
  createProperty,
  editProperty,
  deleteProperty,
  fetchContactsFromBalance,
  fetchContactsFromSMS,
  fetchStaticMap,
  fetchCityDistrictCoords,
  reportProperty,
} from "./requests";

const initialState = {
  golden: null,
  silver: null,
  error: null,
  buildings: null,
  localErrors: {},
  staticMap: {},
  placeCoordinates: null,
  cityCoords: null,
  propertyCategories: [],
  buildingCategories: [],
  property: {},
  propertyContacts: {},
  districts: [],
  cities: [],
  neighborhoods: [],
  propertyView: {},
  parameters: [],
  mandatoryParameters: [],
  parametersSpecific: {
    parameters: [],
    features: [],
    minimum_pictures: 5,
  },
  properties: {
    items: [],
    total_items: 0,
    total_pages: 0,
    current_page: 0,
    page_size: 0,
  },
  isPropertyCleanedUp: true,
};

export const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    setLocalErrors: (state, action) => {
      state.localErrors = action.payload;
    },
    setProperty: (state, action) => {
      state.property = action.payload;
    },
    resetProperty: (state, action) => {
      state.isPropertyCleanedUp = action.payload;
    },
    resetProperties: (state, action) => {
      state.properties = {
        items: [],
        total_items: 0,
        total_pages: 0,
        current_page: 0,
        page_size: 0,
      };
    },
    resetNeighborhoods: (state, _) => {
      state.neighborhoods = [];
    },
    setParametersSpecific: (state, action) => {
      state.parametersSpecific = {
        ...action.payload,
      };
    },
    resetParametersSpecific: (state, action) => {
      state.parametersSpecific = {
        parameters: [],
        features: [],
        minimum_pictures: 5,
        ...action.payload,
      };
    },
    orderImagesAction: (state, action) => {
      let copyOfOrder = action.payload;
      let filesOrder = [];

      let copyOfMedias = state.property.media_files.map((file) => {
        if (file.orderIndex === null) {
          filesOrder.push(copyOfOrder + 1);
          file.orderIndex = copyOfOrder + 1;
          copyOfOrder++;
        } else {
          filesOrder.push(file.orderIndex);
        }
        return file;
      });

      state.property = {
        ...state.property,
        media_files: copyOfMedias,
        media_files_order_indexes: filesOrder,
      };
    },
    setStaticMapData: (state, action) => {
      state.staticMap = action.payload;
    },
    setPropertyContacts: (state, action) => {
      state.propertyContacts = action.payload;
    },
    setCityDistrictCoords: (state, action) => {
      state.placeCoordinates = action.payload;
    },
    setCityCoords: (state, action) => {
      state.cityCoords = action.payload;
    },
  },
  extraReducers: {
    [fetchGolden.fulfilled]: (state, action) => {
      state.golden = action.payload.data.items;
    },
    [fetchSilver.fulfilled]: (state, action) => {
      state.silver = action.payload.data.items;
    },
    [fetchBuildings.fulfilled]: (state, action) => {
      state.buildings = action.payload.data.items;
    },
    [fetchPropertyParams.fulfilled]: (state, action) => {
      state.parameters = action.payload.data.parameters;
      state.mandatoryParameters = action.payload.data.mandatory_parameters;
    },
    [fetchParamsSpecific.fulfilled]: (state, action) => {
      if (state.property.hasOwnProperty("category"))
        state.parametersSpecific = action.payload.data;
    },
    [fetchProperties.fulfilled]: (state, action) => {
      const {
        payload: { data },
      } = action;
      const items = data.items.map((data) => {
        data.cordinates = { lat: data.latitude, lng: data.longitude };
        return { ...data };
      });

      action.payload.data.items = items;
      state.properties = { ...action.payload.data };
    },
    [fetchPropertyCategories.fulfilled]: (state, action) => {
      state.propertyCategories = action.payload.data.categories;
      state.buildingCategories = action.payload.data.buildings;
    },
    [fetchProperty.fulfilled]: (state, action) => {
      const {
        payload: { data },
        meta: { arg },
      } = action;

      let modifiedProperty = { ...data };

      if (modifiedProperty.hasOwnProperty("street")) {
        const regex = ["бул.", "ул.", "пл."];
        let re = new RegExp(regex.join("|"), "i");

        if (re.exec(modifiedProperty.street)) {
          let match = re.exec(modifiedProperty.street)[0];
          modifiedProperty["street"] = modifiedProperty["street"].slice(
            match.length + 1
          );
          modifiedProperty["pre_address"] = match.toLocaleLowerCase();
        }
      }

      if (arg) {
        Object.keys(modifiedProperty).forEach((param) => {
          if (
            modifiedProperty[param] === null ||
            (Array.isArray(modifiedProperty[param]) &&
              modifiedProperty[param].length === 0)
          )
            delete modifiedProperty[param];
          else if (Array.isArray(modifiedProperty[param]))
            modifiedProperty[param] =
              arg.hasOwnProperty("isEdit") &&
              (param === "features" || param === "properties_in_building")
                ? modifiedProperty[param].map((param) => param.id)
                : [...modifiedProperty[param]];
          // else if (arg.hasOwnProperty("isEdit") && param === "title")
          //   modifiedProperty["building_title"] = modifiedProperty[param]
          //     .split(" ")
          //     .slice(-2)[0];
          else if (
            arg.hasOwnProperty("isEdit") &&
            param === "square_price" &&
            !modifiedProperty.hasOwnProperty("price")
          )
            modifiedProperty["price"] = modifiedProperty[param];
          else {
            switch (typeof modifiedProperty[param]) {
              case "object":
                modifiedProperty[param] = arg.hasOwnProperty("isEdit")
                  ? modifiedProperty[param].id
                  : modifiedProperty[param].text;
                break;
              default:
                return (modifiedProperty[param] = modifiedProperty[param]);
            }
          }
        });
      }
      state.property = modifiedProperty;
    },
    [fetchDistricts.fulfilled]: (state, action) => {
      state.districts = action.payload.data.districts;
    },
    [fetchCities.fulfilled]: (state, action) => {
      state.cities = action.payload.data.cities;
    },
    [fetchNeighborhoods.fulfilled]: (state, action) => {
      state.neighborhoods = action.payload.data.neighborhoods;
    },
    [fetchStaticMap.fulfilled]: (state, action) => {
      let copyOfState = { ...state.staticMap };
      copyOfState.blobUrl = action.payload.data;
      state.staticMap = { ...copyOfState };
    },
    [fetchCityDistrictCoords.fulfilled]: (state, action) => {
      const {
        payload: { data },
        meta: { arg },
      } = action;
      if (arg?.isEdit)
        state.cityCoords = {
          lat: data.latitude,
          lng: data.longitude,
        };
      else
        state.placeCoordinates = {
          lat: data.latitude,
          lng: data.longitude,
        };
    },
    [createProperty.fulfilled]: (state, action) => {
      const {
        payload: { data },
        meta: { arg },
      } = action;
      if (!arg.body?.is_draft) state.property = { id: data.id };
    },

    [editProperty.fulfilled]: (state, _) => {
      state.property = {};
    },
    [deleteProperty.fulfilled]: (state, action) => {
      let modifiedProperties = [...state.properties.items];
      if (modifiedProperties.length === 1) {
        modifiedProperties.pop();
      } else {
        const idToRemove = action.payload.data.id;
        modifiedProperties.filter((property) => property.id !== idToRemove);
      }
      state.properties.items = modifiedProperties;
    },
    [fetchContactsFromBalance.fulfilled]: (state, action) => {
      state.propertyContacts = action.payload.data;
    },
    [fetchContactsFromSMS.fulfilled]: (state, action) => {
      state.propertyContacts = action.payload.data;
    },
  },
});

export const {
  setProperty,
  resetProperty,
  resetProperties,
  setLocalErrors,
  orderImagesAction,
  setParametersSpecific,
  resetParametersSpecific,
  setStaticMapData,
  resetNeighborhoods,
  setPropertyContacts,
  setCityDistrictCoords,
  setCityCoords,
} = propertySlice.actions;

export {
  fetchGolden,
  fetchSilver,
  fetchBuildings,
  fetchPropertyParams,
  fetchProperties,
  fetchProperty,
  fetchPropertyCategories,
  fetchParamsSpecific,
  fetchDistricts,
  fetchCities,
  fetchNeighborhoods,
  fetchStaticMap,
  createProperty,
  fetchCityDistrictCoords,
  reportProperty,
  fetchContactsFromSMS,
  fetchContactsFromBalance,
};

export default propertySlice.reducer;
