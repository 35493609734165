import { createSlice } from "@reduxjs/toolkit";
import { fetchDetails, calculate } from "./requests";

const initialState = {
  infoDescription: null,
  infoMain: null,
  annualRate: null,
  annualExpenditureRate: null,
  monthlyPayment: null,
};

export const calculatorSlice = createSlice({
  name: "calculator",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDetails.fulfilled]: (state, action) => {
      state.annualRate = action.payload.data.annual_interest_rate;
      state.infoDescription = action.payload.data.info_description;
      state.infoMain = action.payload.data.main_description;
    },
    [calculate.fulfilled]: (state, action) => {
      state.annualExpenditureRate =
        action.payload.data.annual_interest_rate_of_expenditure;
      state.monthlyPayment = action.payload.data.monthly_payment;
    },
  },
});

export const {} = calculatorSlice.actions;

export { fetchDetails, calculate };

export default calculatorSlice.reducer;
