import { createSlice } from "@reduxjs/toolkit";
import {
  fetchMyDetails,
  fetchMyProperties,
  fetchInvoiceDetails,
  editName,
  editInvoiceDetails,
  changePassword,
  verifyNewEmail,
  verifyNewPhone,
  addFavoriteProperty,
  deleteFavoriteProperty,
  fetchFavoriteProperties,
  saveUserSearch,
  fetchUserSearches,
  fetchUserTransactions,
  fetchMyPackageSlotDetails,
  fetchMyPackageSlots,
  downloadInvoice,
  hasInvoiceDetails,
} from "./requests";

const initialState = {
  user: {},
  invoiceDetails: {},
  hasInvoiceDetails: false,
  myProperties: {
    items: [],
    total_items: 0,
    total_pages: 0,
    current_page: 0,
    page_size: 0,
  },
  myPackageSlots: {
    items: [],
    total_items: 0,
    total_pages: 0,
    current_page: 0,
    page_size: 0,
  },
  myPackageSlotsDetails: {},
  favoriteProperties: {
    items: [],
    total_items: 0,
    total_pages: 0,
    current_page: 0,
    page_size: 0,
  },
  userSearches: {
    items: [],
    total_items: 0,
    total_pages: 0,
    current_page: 0,
    page_size: 0,
  },
  transactions: {
    items: [],
    total_items: 0,
    total_pages: 0,
    current_page: 0,
    page_size: 0,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateMyProperties: (state, action) => {
      state.myProperties.items = action.payload;
    },
  },
  extraReducers: {
    [fetchMyDetails.fulfilled]: (state, action) => {
      state.user = action.payload.data;
    },
    [fetchInvoiceDetails.fulfilled]: (state, action) => {
      state.invoiceDetails = action.payload.data;
    },
    [editInvoiceDetails.fulfilled]: (state, action) => {
      state.invoiceDetails = action.payload.data;
      state.hasInvoiceDetails = true
    },
    [editName.fulfilled]: (state, action) => {
      state.user = { ...state.user, full_name: action.payload.data.full_name };
    },
    [verifyNewEmail.fulfilled]: (state, action) => {
      state.user = {
        ...state.user,
        email: action.payload.data.email,
      };
    },
    [verifyNewPhone.fulfilled]: (state, action) => {
      state.user = {
        ...state.user,
        phone_number: action.payload.data.phone_number,
      };
    },
    [fetchMyProperties.fulfilled]: (state, action) => {
      state.myProperties = action.payload.data;
    },
    [fetchMyPackageSlots.fulfilled]: (state, action) => {
      state.myPackageSlots = action.payload.data;
    },
    [fetchMyPackageSlotDetails.fulfilled]: (state, action) => {
      state.myPackageSlotsDetails = action.payload.data;
    },

    [fetchFavoriteProperties.fulfilled]: (state, action) => {
      state.favoriteProperties = action.payload.data;
    },
    [fetchUserSearches.fulfilled]: (state, action) => {
      state.userSearches = action.payload.data;
    },
    [fetchUserTransactions.fulfilled]: (state, action) => {
      state.transactions = action.payload.data;
    },
    [hasInvoiceDetails.fulfilled]: (state, action) => {
      state.hasInvoiceDetails = action.payload.data.is_invoice_details_available;
    },
    [downloadInvoice.fulfilled]: (_, action) => {
      const {
        payload: { data },
        meta: { arg },
      } = action;
      if (window) {
        const url = window.URL.createObjectURL(data)
        let a = document.createElement('a')
        a.href = url
        a.download = `invoice-${arg.id}`
        a.click()
        a.remove()
        setTimeout(() => window.URL.revokeObjectURL(url), 100)
      }
    },
  },
});

export const { updateMyProperties } = userSlice.actions;

export {
  fetchMyDetails,
  fetchMyProperties,
  fetchInvoiceDetails,
  editName,
  editInvoiceDetails,
  changePassword,
  verifyNewEmail,
  verifyNewPhone,
  addFavoriteProperty,
  deleteFavoriteProperty,
  fetchFavoriteProperties,
  saveUserSearch,
  fetchUserSearches,
  fetchUserTransactions,
  fetchMyPackageSlots,
  fetchMyPackageSlotDetails,
  downloadInvoice,
};

export default userSlice.reducer;
