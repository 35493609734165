import {
  setError,
  clearError,
  showCommonDialog,
  showLoader,
  hideLoader,
} from "./dialogSlice";
import { logout, refresh } from "./authSlice";

let isRefreshing = false;
let pipelineRequests = [];

class ErrorGeneric {
  constructor(message) {
    this.message = message;
    this.name = "ErrorGeneric";
  }
}

const createFormData = (params) => {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    const value = params[key];

    if (Array.isArray(value)) {
      value.map((val, index) => {
        if (
          typeof val === "object" &&
          !(val instanceof Array) &&
          !(val instanceof File)
        )
          Object.keys(val).forEach((k) => {
            formData.append(`${key}[${index}][${k}]`, val[k]);
          });
        else formData.append(key, val);
      });
    } else formData.append(key, value);
  });

  return formData;
};

const processErrorDispatching = ({
  dispatch,
  settings,
  message,
  customConfig,
  endpoint,
}) => {
  if (settings.errorMode === "dialog" && !customConfig.noError) {
    dispatch(showCommonDialog({ message: message }));
  }

  if (settings.errorMode === "error" && customConfig.action) {
    const error =
      settings.asyncCalls[`${customConfig.action}_error`] ?? message;

    dispatch(
      setError({
        name: `${customConfig.action}_error`,
        error: error,
      })
    );
  }

  if (!customConfig.noLoader) {
    dispatch(hideLoader(endpoint));
  }

  return Promise.reject(new ErrorGeneric(message));
};

const refreshToken = async (settings, dispatch) => {
  const { storage } = settings;

  const refreshToken = await storage.getRefreshToken();

  if (!!refreshToken) {
    const response = await dispatch(refresh({ refresh_session_id: refreshToken }));
    const { payload } = response;

    if (payload?.ok) {
      return {
        ok: payload.ok,
        status: payload.status,
        data: payload.data,
      };
    }
  }

  return null;
};

const setTokens = async (storage, response) => {
  if (response.ok && response.data.access_id && response.data.refresh_id) {
    await storage.setTokens(response.data.access_id, response.data.refresh_id);
    return true;
  } else {
    await storage.removeTokens();
    return false;
  }
};

const removeTokens = async (storage) => {
  await storage.removeTokens();
};

const execute = async (dispatch, settings, endpoint, body, customConfig) => {
  const { storage } = settings;
  const headers = {
    Accept: "*/*",
  };

  if (!customConfig.formData) {
    if (customConfig.contentType) headers["Content-Type"] = customConfig.contentType;
    else headers["Content-Type"] = `application/json`;
  }

  const accessToken = await storage.getAccessToken();
  if (accessToken) {
    headers["Authorization"] = `Session ${accessToken}`;
  }

  const config = {
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = customConfig.formData
      ? createFormData(body)
      : JSON.stringify(body);
  }

  let data;
  try {
    if (!customConfig.noLoader) {
      dispatch(showLoader(endpoint));
    }

    let response = await window.fetch(endpoint, config);

    if (response.status === 401) {
      if (!isRefreshing) {
        // prevents incoming unauthorized requests
        isRefreshing = true;

        const refreshTokenResponse = await refreshToken(settings, dispatch);

        if (
          !!refreshTokenResponse &&
          (await setTokens(storage, refreshTokenResponse))
        ) {
          const accessToken = await storage.getAccessToken();
          config.headers["Authorization"] = `Session ${accessToken}`;

          response = await window.fetch(endpoint, config);
          if (response.ok) {
            // on successfull refresh set isRefreshing to false to stop pipeline-ing incoming requests
            isRefreshing = false;
            // execute pipeline

            pipelineRequests.forEach((req) => dispatch(req()));
            pipelineRequests = [];
          }
        } else {
          isRefreshing = false;

          if (!customConfig.noLoader) {
            dispatch(hideLoader(endpoint));
          }
          dispatch(logout());

          return Promise.reject(new ErrorGeneric(""));
        }
      } else {
        // if there is repeat function push it to the pipleline to be executed when token refresh stops
        if (!!customConfig?.repeatFuncOnRefreshedToken) {
          let copyOfCurrentRequests = [...pipelineRequests];
          copyOfCurrentRequests.push(customConfig.repeatFuncOnRefreshedToken);
          pipelineRequests = [...copyOfCurrentRequests];
        }
      }
    }

    const contentType = response.headers.get("Content-Type");

    if (response.status === 202 || response.status === 204) {
      data = {};
    } else {
      if (contentType?.startsWith("image/") || contentType?.endsWith("pdf")) {
        data = await response.blob();
      } else {
        data = await response.json();
      }
    }

    if (response.ok) {
      if (settings.errorMode === "error" && customConfig.action) {
        dispatch(clearError({ name: `${customConfig.action}_error` }));
      }

      if (customConfig.action) {
        const message = settings.asyncCalls[`${customConfig.action}_success`];

        if (message) {
          dispatch(showCommonDialog({ message }));
        }
      }

      if (!customConfig.noLoader) {
        dispatch(hideLoader(endpoint));
      }

      return {
        ok: response.ok,
        status: response.status,
        data,
      };
    } else if (response.status === 400) {
      const message = data.ErrorCode
        ? (settings.errors && settings.errors[data.ErrorCode]) ?? data.ErrorCode
        : JSON.stringify(data);

      return processErrorDispatching({
        dispatch,
        settings,
        message,
        customConfig,
        endpoint,
      });
    } else {
      let message = `${response.status} : ${JSON.stringify(data)}`;

      if (settings.logTraceIdOnReject === true) {
        message = `traceId: ${response.headers.map["x-trace-id"]}`;
      }

      return processErrorDispatching({
        dispatch,
        settings,
        message,
        customConfig,
        endpoint,
      });
    }
  } catch (err) {
    let message = err.message ? err.message : "Възникна неочаквана грешка!";
    if (settings?.errors?.Ec_no_network && err.message == 'Network request failed') {
      message = settings.errors.Ec_no_network
    } else {
      message = settings?.errors?.Ec_invalid_data ?? err.message
    }

    if (settings?.errors?.unexpectedError) {
      message = settings.errors.unexpectedError
    }

    return processErrorDispatching({
      dispatch,
      settings,
      message,
      customConfig,
      endpoint,
    });
  }
};

const client = {};

client.refreshToken = refreshToken;

client.get = async (dispatch, settings, endpoint, customConfig = {}) => {
  return execute(dispatch, settings, endpoint, null, {
    ...customConfig,
    method: "GET",
  });
};

client.delete = async (
  dispatch,
  settings,
  endpoint,
  body,
  customConfig = {}
) => {
  return execute(dispatch, settings, endpoint, body, {
    ...customConfig,
    method: "DELETE",
  });
};

client.post = async (dispatch, settings, endpoint, body, customConfig = {}) => {
  return execute(dispatch, settings, endpoint, body, {
    ...customConfig,
    method: "POST",
  });
};

client.put = async (dispatch, settings, endpoint, body, customConfig = {}) => {
  return execute(dispatch, settings, endpoint, body, {
    ...customConfig,
    method: "PUT",
  });
};

client.setTokens = setTokens;
client.removeTokens = removeTokens;

export { client };
