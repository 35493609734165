const isBrowser = typeof window !== "undefined";

const localStorage = isBrowser ? window.localStorage : null;

const _refreshToken = "fba_y";
const _accessToken = "fba_x";
const _searches = "search";

const getRefreshToken = () =>
  isBrowser ? localStorage.getItem(_refreshToken) : null;

const getAccessToken = () =>
  isBrowser ? localStorage.getItem(_accessToken) : null;

const setTokens = (accessToken, refreshToken) => {
  if (isBrowser) {
    localStorage.setItem(_accessToken, accessToken);
    localStorage.setItem(_refreshToken, refreshToken);
  }
};

const removeTokens = () => {
  if (isBrowser) {
    localStorage.removeItem(_accessToken);
    localStorage.removeItem(_refreshToken);
  }
};

const storage = {};

storage.isBrowser = isBrowser;

storage.getRefreshToken = async () => getRefreshToken();

storage.getAccessToken = async () => getAccessToken();

storage.setTokens = async (accessToken, refreshToken) =>
  setTokens(accessToken, refreshToken);

storage.removeTokens = async () => removeTokens();

storage.setLastSearches = async (params) => {
  if (isBrowser) localStorage.setItem(_searches, params);
};

storage.getLastSearches = async (params) =>
  isBrowser ? localStorage.getItem(_searches, params) : null;

export { storage };
