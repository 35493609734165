import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "querystring";
import { client } from "@a1-ict/core/client";

export const fetchLogos = createAsyncThunk(
  "banner/fetchLogos",
  async ({ count }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(
      dispatch,
      settings,
      `${api_url}/logo?Count=${count}`
    );
  }
);

export const fetchBanner = createAsyncThunk(
  "banner/fetchBanner",
  async (_, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.get(dispatch, settings, `${api_url}/banner`);
  }
);

export const countLogo = createAsyncThunk(
  "banner/countLogo",
  async ({ id }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.put(dispatch, settings, `${api_url}/logo/${id}/click`);
  }
);

export const countBanner = createAsyncThunk(
  "banner/countBanner",
  async ({ id }, { dispatch, extra: { settings } }) => {
    const { api_url } = settings;

    return await client.put(
      dispatch,
      settings,
      `${api_url}/banner/${id}/click`
    );
  }
);
