import { createSlice } from "@reduxjs/toolkit";

import { fetchNotifications, readNotification } from "./requests";

const initialState = {
  notifications: {},
  toast: null,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setToast: (state, action) => {
      state.toast = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications.items = action.payload;
    },
  },
  extraReducers: {
    [fetchNotifications.fulfilled]: (state, action) => {
      state.notifications = action.payload.data;
    },
  },
});

export const { setToast, setNotifications } = notificationsSlice.actions;

export { fetchNotifications, readNotification };

export default notificationsSlice.reducer;
